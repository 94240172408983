<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ editData ? 'Edit' : 'Add' }} Visit Plan</h1>
                            <button 
                                @click="closeModal" 
                                type="button" 
                                class="btn-close" 
                                data-bs-dismiss="modal"
                                aria-label="Close">
                            </button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">

                <div class="modal-body" :class="route.name === 'visit-calendar' && 'disabled-element'">
                    <div class="pb-2">
                        <div class="row">
                            
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Start Time</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        v-model="formData.start_time"
                                        name="datetime"
                                        type="datetime-local"
                                        class="form-control"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">End Time</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        v-model="formData.end_time"
                                        name="datetime"
                                        type="datetime-local"
                                        class="form-control"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">BDO</label>
                                <AsyncSelect
                                    placeholder="Select BDO name"
                                    v-model="saleRepresentative"
                                    :api-service="fetchAllSR"
                                    @option:selected="onchangeSrId"
                                    :disabled="route.name === 'my-visit-schedule'"
                                />
                            </div>

                            <div :class="![route.name, route.query.from].includes('my-visit-schedule') && 'disabled-element'" class="col-12 mb-1">
                                <div class="d-flex align-items-center justify-content-between filter-label">
                                    <div>
                                        <label for="colFormLabel" class="col-form-label mr-5">Select Clients: </label>
                                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                                            <i class="fas fa-search"></i>
                                        </span>
                                    </div>
                                    
                                    <button @click="addNewCustomer" class="btn btn-primary btn-sm">+ Add Customer</button>
                                </div>
                                <AsyncSelect
                                    placeholder="Select Clients"
                                    v-model="formData.contacts"
                                    :api-service="fetchContactProfiles"
                                    :additional-query="additionalClientQuery"
                                    :isMultiple="true"
                                    :additional-option="additionalOption"
                                    :key="clientRerenderKey"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Short Description</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        name="name"
                                        v-model="formData.short_description"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter short description"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Full Description</label>
                                <div class="input-group input-group-merge w-100">
                                    <textarea 
                                        v-model="formData.full_description"
                                        name="reference" rows="2"
                                        placeholder="Enter full description"
                                        class="form-control add-credit-card-mask"
                                    >
                                    </textarea>
                                </div>
                            </div>

                            <div class="col-12 text-center">

                                <hr class="mt-2">

                                <button 
                                    v-if="route.name !== 'visit-calendar' && !['completed', 'approved', 'rejected'].includes(formData.status)"
                                    :disabled="loading" @click="submit"
                                    class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light"
                                >
                                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    Submit
                                </button>

                                <button @click="closeModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <AddContactModal
                    v-if="store.state.isModalOpenTwo"
                    @onCreateProfile="onCreateProfile"
                    :additionalProperties="additionalProperties"
                    :isHideDealer="true"
                    contactType="organization"
                    :companyRoles="companyRoles"
                >
                    <div class="col-12 mb-1">
                        <label class="form-label new-customer">Bank-Branch</label>
                        <keep-alive>
                            <AsyncSelect
                                placeholder="Select Bank-Branch"
                                v-model="additionalProperties.dealer_id"
                                :api-service="fetchAllDealers"
                                :additional-query="additionalDealerQuery"
                                :reduce="option => option.id"
                                :additional-option="additionalContactDealerOption"
                            />
                        </keep-alive>
                    </div>
                </AddContactModal>
            </div>
        </div>
        <ContactDropdownFilter 
            v-if="store.state.isModalOpenFour"
            @onSearchContact="onSearchContact"
            :companyRoles="companyRoles"
            :isFilterable="true"
        />
    </div>
</template>

<script setup>
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import AddContactModal from '@/components/atom/Contact/AddContactModal'
import { getCurrentDateTime } from '@/services/utils/global.js'
import { inject, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import handleContact from '@/services/modules/contact';
import handleCrm from '@/services/modules/crm/crm'
import handleRole from "@/services/modules/role";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'

const props = defineProps({
    editData: Object | null,
    onSubmit: Function,
    noteStatus: Array,
    companyRoles: {
        type: Array,
        default: () => []
    }
})

const emit = defineEmits(['onSubmit']);

const { fetchContactProfiles } = handleContact();
const { fetchSRDetailsByUser, storeVisitPlan, updateVisitPlan, fetchAllSR, fetchAllDealers } = handleCrm();
const { fetchCompanyDefaultRoles } = handleRole();

const store = useStore();
const route = useRoute();

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false);
const additionalOption = ref([]);
const additionalContactDealerOption = ref([]);
const additionalDealerOption = ref([]);
const additionalDealerQuery = ref({});
const additionalClientQuery = ref({
    roles: 'customer,lead',
    dealer_id: ''
});
const additionalProperties = ref({
    dealer_id: ''
})
const rerenderKey = ref(0);
const clientRerenderKey = ref(0);
const singleDealer = ref(null);
const saleRepresentative = ref(null)
const companyRoles = ref([]);

const formData = reactive({
    company_id: null,
    contact_profile_id: null,
    sale_representative_id: null,
    dealer_id: null,
    area_name: '',
    start_time: getCurrentDateTime(),
    end_time: '',
    short_description: '',
    full_description: '',
    contacts: [],
    status: 'pending'
});

watch(() => formData.dealer_id, (newValue) => {
    if(newValue) return;
    onChangeDealer();    
})

watch(() => saleRepresentative.value, (newValue) => {
    if(newValue) return;
    onchangeSrId();
})

const onchangeSrId = (sr = null) => {
    rerenderKey.value ++
    additionalDealerOption.value = [];
    additionalDealerQuery.value = {};
    formData.dealer_id = null;
    formData.area_name = '';
    if(sr){
        additionalDealerQuery.value = { sale_representative_id: sr.id };
    }
}

const onChangeDealer = (dealer = null) => {
    clientRerenderKey.value += 1;
    additionalClientQuery.value.dealer_id = '';
    additionalProperties.value.dealer_id = '';
    formData.contact_profile_id = null;
    formData.contacts = [];
    singleDealer.value = null;
    formData.area_name = '';

    if(dealer) {
        additionalClientQuery.value.dealer_id = dealer.id
        additionalProperties.value.dealer_id = dealer.id
        singleDealer.value = dealer
        additionalContactDealerOption.value.push(dealer)
        formData.area_name = dealer.area_name
    }
}

const addNewCustomer = () => {
    store.state.isModalOpenTwo = true
}

const getCompanyRoles = async () => {
    const res = await fetchCompanyDefaultRoles(`?company_id=${route.params.companyId}&roles=customer,lead`);
    if (res.status) {
        companyRoles.value = res.data
    }
}

const onOpenContactSearchModal = () => {
    store.state.isModalOpenFour = true;
}

const onSearchContact = (contact) => {
    store.state.isModalOpenFour = false;
    onCreateProfile(contact)
}

const onCreateProfile = (user) => {
    
    const contact = {
        id: user.id,
        name: user.full_name
    }

    if(route.name === 'my-visit-schedule') {
        formData.contacts.push(contact)
        return;
    }

    formData.contact_profile_id = user.id
    additionalOption.value.push(contact)
}

const closeModal = () => {
    store.state.isModalOpen = false;
}

const submit = async () => {

    const formattedData = formatFormData();

    const id = props.editData ? props.editData.id : null;
    const visitPlanAction = id ? updateVisitPlan : storeVisitPlan;

    loading.value = true

    await visitPlanAction(formattedData, id).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message);
        closeModal();
        emit('onSubmit');
        return showSuccess(res.message);
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const formatFormData = () => {
    const newFormData = structuredClone(formData);
    newFormData.company_id = route.params.companyId;

    if(saleRepresentative.value) newFormData.sale_representative_id = saleRepresentative.value.id

    if(formData.contacts.length){
        newFormData.contacts = formData.contacts.map(item => {
            return {
                contact_profile_id: item.id,
                id: item.pivot ? item.pivot.id : null
            }
        })
    }
    return newFormData;
}

const setEditData = (data) => {

    formData.contact_profile_id = data.contact_profile_id
    formData.sale_representative_id = data.sale_representative_id
    formData.dealer_id = data.dealer_id
    formData.area_name = data.area_name
    formData.start_time = getCurrentDateTime(data.start_time);
    formData.short_description = data.short_description
    formData.full_description = data.full_description
    formData.activity_log_id = data.activity_log_id
    formData.status = data.status

    if(data.end_time) formData.end_time = getCurrentDateTime(data.end_time);

    if(data.contact_profile_id){
        additionalOption.value.push({
            id: data.contact_profile_id,
            name: data.client_name
        })
    }
    
    if(data.sale_representative_id){
        saleRepresentative.value = {
            id: data.sale_representative_id,
            name: data.sr_name
        }
    }

    if(data.dealer_id){
        const dealer = {
            id: data.dealer_id,
            name: data.dealer_name
        };
        additionalProperties.value.dealer_id = dealer.id
        additionalDealerOption.value.push(dealer)
        additionalContactDealerOption.value.push(dealer)
    }

    if(data.visit_plan_contacts && data.visit_plan_contacts.length){
        formData.contacts = data.visit_plan_contacts
    }
}

const getSingleSR = async () => {
    const companyQuery = `?company_id=${route.params.companyId}`
    await fetchSRDetailsByUser(companyQuery, store.state.user.user.id).then((res) => {
        if (res.status) {
            saleRepresentative.value = {
                id: res.data.id,
                name: res.data.name
            }
        }
    })
}

onMounted(async () => {
    if(props.editData){
        setEditData(props.editData)
    }

    if(route.name === 'my-visit-schedule' && ! props.editData) {
        await getSingleSR();
    }

    await getCompanyRoles()
})

</script>


<style scoped>
.new-customer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.med-title {
    padding: 0.486rem 1.5rem;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
.filter-label {
    padding-right: 0.5rem !important;
}
</style>