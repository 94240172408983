<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                :showAddNew="true"
                :title="title"
                btnTitle="Visit Client"
                @onClickAddNewButton="onClickAddNew"
                :showExtraBtn="true"
                extraBtnTitle="Calendar View"
                @onClickExtraBtn="goToCalendar"
            >
                <template #title-header-buttons>
                    <button
                        @click="goToVisitBank()"
                        class="btn btn-outline-primary waves-effect waves-float waves-light mr-25"
                    >Visit Bank</button>
                </template>

                <template #title-buttons>
                    <button
                        @click="addNewClient"
                        class="btn btn-primary waves-effect waves-float waves-light mr-25"
                    > + New Client
                    </button>
                </template>
            </TitleButton>
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
            >
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mr-top-1">
                    <label class="form-label">Select BDO</label>
                    <AsyncSelect
                        placeholder="Select BDO"
                        v-model="singleSR"
                        :api-service="fetchAllSR"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mr-top-1">
                    <label for="colFormLabel" class="form-label">Bank-Branch</label>
                    <AsyncSelect
                        placeholder="Select Bank-Branch"
                        v-model="singleDealer"
                        :api-service="fetchAllDealers"
                        :additional-query="additionalDealerQuery"
                        :additional-option="additionalDealerOption"
                        :key="dealerRenderKey"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Client: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    <AsyncSelect
                        placeholder="Select Client"
                        v-model="contactProfile"
                        :api-service="fetchContactProfiles"
                        :additional-query="additionalContactOption"
                        :format-label="formatPatientLabel"
                        :additional-option="additionalOption"
                        :option-selected="onChangeContact"
                        :key="contactRenderKey"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mr-top-1">
                    <label class="form-label">Select location</label>
                    <AsyncSelect
                        placeholder="Select location"
                        v-model="area"
                        :api-service="fetchAreaList"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mr-top-1">
                    <label class="form-label">Status</label>
                    <v-select
                        placeholder="Select Visit Status"
                        label="name"
                        :options="visitStatuses"
                        v-model="visitStatus"
                        :reduce="option => option.value"
                    />
                </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable
                :tableItems="tableItems"
                @onEditVisitPlan="onEditVisitPlan"
            />
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>

        <AddOrEditVisitPlanModal
            v-if="store.state.isModalOpen"
            :noteStatus="noteStatus"
            :editData="editData"
            @onSubmit="onSubmit"
            :companyRoles="companyRoles"
        />

        <ContactDropdownFilter 
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            :companyRoles="companyRoles"
            :isFilterable="true"
        />

        <AddContactModal
            v-if="$store.state.isModalOpenFour"
            modalStateKey="isModalOpenFour"
            contactType="organization"
            :companyRoles="companyRoles"
        />
    </div>
</template>

<script setup>
import TitleButton from '@/components/molecule/company/crm/visit-plan/VisitTitle.vue';
import { computed, inject, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import AddOrEditVisitPlanModal from '@/components/molecule/company/crm/visit-plan/AddOrEditVisitPlanModal.vue';
import ListTable from '@/components/molecule/company/crm/visit-plan/VisitPlanTable.vue';
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleCrm from '@/services/modules/crm/crm.js'
import handleContact from "@/services/modules/contact";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import handleRole from "@/services/modules/role";
import AddContactModal from '@/components/atom/Contact/AddContactModal.vue'

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const params = reactive({
    searchTxt: '',
    offset: 10
});
const visitStatus = ref(route.name === 'my-visit-schedule' ? 'approved' : 'pending');
const contactProfile = ref(null)
const singleSR = ref(null);
const singleDealer = ref(null);
const area = ref(null);
const dealerRenderKey = ref(0);
const contactRenderKey = ref(0);

const additionalOption = ref([]);
const additionalDealerQuery = ref({});
const additionalContactOption = ref({
    with_mobile: 1,
    dealer_id: ''
});
const additionalDealerOption = ref([]);
const companyRoles = ref([]);
const visitStatuses = ref([]);

const editData = ref(null);
const noteStatus = reactive([
    {
        value: 'active',
        name: 'Active',
    },
    {
        value: 'inactive',
        name: 'Inactive'
    }
])

const { fetchVisitPlanList, fetchSingleVisitPlan, fetchAllDealers, fetchAllSR, fetchAreaList, fetchVisitStatus } = handleCrm();
const { fetchContactProfiles } = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchCompanyDefaultRoles } = handleRole();

const title = computed(() => {
    if(route.name === 'my-visit-schedule'){
        return 'My Visit Schedule';
    }
    return 'Visit Schedule';
})

const start = computed(() => route.query.start);
const end = computed(() => route.query.end);

const onClickAddNew = () => {
    editData.value = null;
    store.state.isModalOpen = true;
}

const onChangeContact = (e) => {
    //TODO: contact profile id
}

const goToVisitBank = () => {
    const query = Object.assign({}, route.query);
    query.from = route.name

    router.push({
        name: 'visit-bank-client',
        params: route.params,
        query: query
    })
}

const addNewClient = () => {
    store.state.isModalOpenFour = true;
}

const getCompanyRoles = async () => {
    const res = await fetchCompanyDefaultRoles(`?company_id=${route.params.companyId}&roles=customer,lead`);
    if (res.status) {
        companyRoles.value = res.data
    }
}

const getVisitStatus = async () => {
    const res = await fetchVisitStatus(`?company_id=${route.params.companyId}`);
    if (res.status) {
        visitStatuses.value = res.data
    }
}

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
    contactProfile.value = contact
}

const goToCalendar = () => {
    router.push({
        name: 'visit-calendar',
        params: route.params,
        query: {
            from: route.name
        }
    })
}

const onClickGo = () => {
    getVisitPlanList()
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const onSubmit = () => {
    getVisitPlanList();
}

const onEditVisitPlan = async (item) => {

    const query = getQuery();

    await fetchSingleVisitPlan(query, item.id).then((res) => {
        if(!res.status) return showError(res.message);
        editData.value = res.data;
        store.state.isModalOpen = true;
    })
}

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

async function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    router.push({path: route.path, query: routeQuery})
    await getVisitPlanList();
}

const getVisitPlanList = async () => {

    const query = getQuery();
    loading.value = true

    await fetchVisitPlanList(query).then(res => {
        loading.value = false
        if (!res.status){
            tableItems.value = []
            store.commit("resetPagination")
            return
        }
        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (start.value && end.value) query += `&start_date=${start.value}&end_date=${end.value}`
    if (route.query.page) query += '&page=' + route.query.page
    if(params.searchTxt) query += `&search_txt=${params.searchTxt}`;
    if(route.name === 'my-visit-schedule') query += `&my_plan=1`;
    if(singleSR.value) query += `&sale_representative_id=${singleSR.value.id}`;
    if(singleDealer.value) query += `&dealer_id=${singleDealer.value.id}`;
    if(contactProfile.value) query += `&contact_profile_id=${contactProfile.value.id}`;
    if(area.value) query += `&area_id=${area.value.id}`;
    if(visitStatus.value) query += `&status=${visitStatus.value}`
    query += '&offset=' + params.offset
    return query;
}

watch(() => singleSR.value, (newValue, oldValue) => {
    additionalDealerQuery.value = {};
    additionalContactOption.value.dealer_id = '';
    singleDealer.value = null;
    contactProfile.value = null;
    dealerRenderKey.value += 1;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalDealerQuery.value = {'sale_representative_id': newValue.id}
    }
})

watch(() => singleDealer.value, (newValue, oldValue) => {
    additionalContactOption.value.dealer_id = '';
    contactProfile.value = null;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalContactOption.value.dealer_id = newValue.id
    }
})

onMounted(async () => {
    Promise.all([
        getVisitPlanList(),
        getCompanyRoles(),
        getVisitStatus()
    ])
})
</script>

<style scoped>
.mr-25 {
  margin-right: 25px;
}
.mr-top-1 {
    margin-top: 1% !important;
}
.filter-label {
    padding-right: 0.5rem !important;
}
</style>