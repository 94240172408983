<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    <tr>
                        <th>Date & Time</th>
                        <th class="name">BDO Name</th>
                        <th class="name">Bank-Branch</th>
                        <th>Location</th>
                        <th class="name">Client</th>
                        <th class="action-th">Action</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">

                        <td>
                            {{ item.start_time }}
                        </td>

                        <td>
                            {{ item.sr_name }}
                        </td>

                        <td>
                            {{ item.dealer_name }}
                        </td>

                        <td>
                            {{ item.area_name }}
                        </td>

                        <td>
                            {{ item.client_name }}
                        </td>

                        <td>
                            <div class="action">
                                <button @click="onEditVisitPlanSchedule(item)" class="btn btn-primary btn-sm mr-5">
                                    <i class="fas fa-eye"></i>
                                </button>
                                <button v-if="$route.name === 'my-visit-schedule' && item.status === 'approved'" @click="updateStatus(item)" class="btn btn-primary btn-sm">
                                    Update Status
                                </button>
                                <button v-if="$route.name === 'visit-plan-list' && item.status === 'pending'"  @click="updateStatus(item, 'manager')" class="btn btn-success btn-sm mr-5">Approve</button>
                                <button v-if="$route.name === 'visit-plan-list' && item.status === 'pending'"  @click="updateStatus(item, 'manager')" class="btn btn-danger btn-sm">Reject</button>
                                <button v-if="$route.name === 'visit-plan-list' && item.status === 'completed'" @click="updateStatus(item)" class="btn btn-primary btn-sm">
                                    Update Status
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
    import { useRoute, useRouter } from "vue-router";

    const route = useRoute();
    const router = useRouter();

    const props = defineProps({
        tableItems: {
            type: Array,
        },
        onEditVisitPlan: Function,
    })

    const emit = defineEmits(['onEditVisitPlan']);

    const onEditVisitPlanSchedule = (item) => {
        emit('onEditVisitPlan', item)
    }

    const updateStatus = (item, viewType = '') => {
        let routeParams = Object.assign({}, route.params)
        routeParams.planId = item.id;

        const query = {
            start: route.query.start,
            end: route.query.end,
            from: route.name
        }

        if(viewType){
            query.viewType = viewType
        }

        router.push({
            name: 'visit-status-update',
            params: routeParams,
            query: query
        })
    }

</script>

<style scoped>
.mr-5{
    margin-right: 5px;
}
.action-th{
    width: 25%;
    text-align: center;
}
.name{
    width: 15%;
}
.action{
    text-align: center;
}

.profile-pic{
    width: 65px;
}
</style>