<template>
    <div class="row">
      <div class="col-5">
        <p class="h1 m-0">{{ title }}</p>
      </div>
      <div class="col-7">
        <div class="float-end">
          <a v-if="showSettingBtn" href="#" @click="$emit('onClickSettingButton')" class="mr mr-25">Setting</a>
          <slot name="title-header-buttons"></slot>
          <button
              v-if="showAddNew"
              @click="$emit('onClickAddNewButton')"
              class="btn btn-outline-primary waves-effect waves-float waves-light mr-25"
          >{{ btnTitle }}
          </button>

          <slot name="title-buttons"></slot>
  
          <button
              v-if="showExtraBtn"
              @click="$emit('onClickExtraBtn')"
              class="btn btn-primary waves-effect waves-float waves-light mr-25"
          >
            {{ extraBtnTitle }}
          </button>
  
          <button
              v-if="showBtn"
              @click="$emit('onClickCloseButton')"
              class="btn btn-close"
          >
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TitleButton',
    props: {
      title         : String,
      btnTitle      : String,
      extraBtnTitle      : String,
      showBtn       : {
        default: true
      },
      showAddNew    : {
        default: false
      },
      showExtraBtn    : {
        default: false
      },
      showSettingBtn: {
        default: false
      }
    }
  }
  </script>
  
  <style scoped>
  .mr-25 {
    margin-right: 25px;
  }
  .cancel-btn {
    background-color: #F0F2F5;
    color: #7D7D7D;
  }
  
  .mr{
    margin-right: 1rem;
  }
  
  .cancel-btn:hover {
    background-color: #e9edf5;
  }
  
  hr {
    margin: 0 -3%;
    width: 106%;
  }
  
  @media screen and (min-width: 1200px) {
    .btn-close {
      width: 30px;
      height: 30px;
      background-color: #E4E6EB;
      border-radius: 50%;
    }
  
    .btn-close:hover {
      width: 30px;
      height: 30px;
      background-color: #D8DADF;
      border-radius: 50%;
    }
  }
  
  @media screen and (max-width: 720px) {
    .btn-close {
      width: 25px;
      height: 25px;
      background-color: #E4E6EB;
      border-radius: 50%;
      margin-top: -5px;
      margin-right: -5px;
    }
  }
  </style>
  